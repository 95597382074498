import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
// import Loadable from 'react-loadable'
import ErrorBoundary from 'components/CustomComponents/ErrorBoundary'
import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import { ROLES } from '_constants'
import { connect } from 'react-redux'
import PrivateRoute from './PrivateRoute'

// const loadable = (loader) => React.lazy(loader) // added
function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error)
            return
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, 1500)
      })
  })
}

const loadable = (loader) => React.lazy(() => componentLoader(loader, 5))
// Loadable({
//   loader,
//   // delay: false,
//   loading: () => <Loader />,
//   delay: 200
// })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  // Dashboards
  {
    path: '/dashboard',
    component: loadable(() => import('pages/dashboard/alpha')),
  },
  {
    path: '/dashboard/seller',
    component: loadable(() => import('pages/dashboard/seller')),
  },
  // modules
  {
    path: '/category',
    component: loadable(() => import('pages/modules/list')),
    exact: true,
  },
  {
    path: '/category/add-edit',
    component: loadable(() => import('pages/modules/add-edit')),
    exact: true,
  },
  {
    path: '/category/add-edit/:id',
    component: loadable(() => import('pages/modules/add-edit')),
    exact: true,
  },
   // policies
   {
    path: '/policies',
    component: loadable(() => import('pages/policies/list')),
    exact: true,
  },
  {
    path: '/policies/add-edit',
    component: loadable(() => import('pages/policies/add-edit')),
    exact: true,
  },
  {
    path: '/policies/add-edit/:id',
    component: loadable(() => import('pages/policies/add-edit')),
    exact: true,
  },
  // packages
  {
    path: '/venue',
    component: loadable(() => import('pages/packages/list')),
    exact: true,
  },
  {
    path: '/venue/add-edit',
    component: loadable(() => import('pages/packages/add-edit')),
    exact: true,
  },
  {
    path: '/venue/add-edit/:id',
    component: loadable(() => import('pages/packages/add-edit')),
    exact: true,
  },

  // /providertype
  {
    path: '/providertype',
    component: loadable(() => import('pages/PROVIDER/providerType/list')),
    exact: true,
  },
  {
    path: '/providertype/add-edit',
    component: loadable(() => import('pages/PROVIDER/providerType/add-edit')),
    exact: true,
  },
  {
    path: '/providertype/add-edit/:id',
    component: loadable(() => import('pages/PROVIDER/providerType/add-edit')),
    exact: true,
  },
  // /provider
  {
    path: '/provider',
    component: loadable(() => import('pages/PROVIDER/provider/list')),
    exact: true,
  },
  {
    path: '/provider/add-edit',
    component: loadable(() => import('pages/PROVIDER/provider/add-edit')),
    exact: true,
  },
  {
    path: '/provider/add-edit/:id',
    component: loadable(() => import('pages/PROVIDER/provider/add-edit')),
    exact: true,
  },

  // /providerscheduling
  {
    path: '/providerscheduling',
    component: loadable(() => import('pages/PROVIDER/Scheduling/list')),
    exact: true,
  },
  {
    path: '/providerscheduling/add-edit',
    component: loadable(() => import('pages/PROVIDER/Scheduling/add-edit')),
    exact: true,
  },
  {
    path: '/providerscheduling/add-edit/:id',
    component: loadable(() => import('pages/PROVIDER/Scheduling/add-edit')),
    exact: true,
  },
  // /hospital

  {
    path: '/hospital',
    component: loadable(() => import('pages/hospitals/list')),
    exact: true,
  },
  {
    path: '/hospital/add-edit',
    component: loadable(() => import('pages/hospitals/add-edit')),
    exact: true,
  },
  {
    path: '/hospital/add-edit/:id',
    component: loadable(() => import('pages/hospitals/add-edit')),
    exact: true,
  },

  // /branch
  {
    path: '/branch',
    component: loadable(() => import('pages/branches/list')),
    exact: true,
  },
  {
    path: '/branch/add-edit',
    component: loadable(() => import('pages/branches/add-edit')),
    exact: true,
  },
  {
    path: '/branch/add-edit/:id',
    component: loadable(() => import('pages/branches/add-edit')),
    exact: true,
  },

  // /patient
  {
    path: '/amenity',
    component: loadable(() => import('pages/patients/list')),
    exact: true,
  },
  {
    path: '/amenity/add-edit',
    component: loadable(() => import('pages/patients/add-edit')),
    exact: true,
  },
  {
    path: '/amenity/add-edit/:id',
    component: loadable(() => import('pages/patients/add-edit')),
    exact: true,
  },
  // /users
  {
    path: '/users',
    component: loadable(() => import('pages/Users/list')),
    exact: true,
  },
  {
    path: '/users/add-edit',
    component: loadable(() => import('pages/Users/add-edit')),
    exact: true,
  },
  {
    path: '/users/add-edit/:id',
    component: loadable(() => import('pages/Users/add-edit')),
    exact: true,
  },
 // /customer
  {
    path: '/customers',
    component: loadable(() => import('pages/Customer/list')),
    exact: true,
  },
  {
    path: '/customers/add-edit',
    component: loadable(() => import('pages/Customer/add-edit')),
    exact: true,
  },
  {
    path: '/customers/add-edit/:id',
    component: loadable(() => import('pages/Customer/add-edit')),
    exact: true,
  },
  // appointment-type

  {
    path: '/appointment-type',
    component: loadable(() => import('pages/appointmentType/list')),
    exact: true,
  },
  {
    path: '/appointment-type/add-edit',
    component: loadable(() => import('pages/appointmentType/add-edit')),
    exact: true,
  },
  {
    path: '/appointment-type/add-edit/:id',
    component: loadable(() => import('pages/appointmentType/add-edit')),
    exact: true,
  },

  // reshedule
  {
    path: '/appointment/:appointmentId/reschedule/:hospitalId',
    component: loadable(() => import('pages/resheduleAppointment')),
    exact: true,
  },
  // appointment-type

  {
    path: '/reservation',
    component: loadable(() => import('pages/bookings/list')),
    exact: true,
  },
  // {
  //   path: '/booking/add-edit',
  //   component: loadable(() => import('pages/bookings/add-edit')),
  //   exact: true,
  // },
  {
    path: '/reservation/add-edit/:id',
    component: loadable(() => import('pages/bookings/add-edit')),
    exact: true,
  },
  // Edit profile
  {
    path: '/edit-profile',
    component: loadable(() => import('pages/edit-profile')),
    exact: true,
    // authorize: {
    //   role: ['admin'],
    // },
  },
  // {
  //   path: '/mail-settings',
  //   component: loadable(() => import('pages/mail-settings')),
  //   exact: true,
  // },
  // {
  //   path: '/general-settings',
  //   component: loadable(() => import('pages/general-settings')),
  //   exact: true,
  // },
  // {
  //   path: '/footer-settings',
  //   component: loadable(() => import('pages/footer-settings')),
  //   exact: true,
  // },
  //  // Users Type
  //  {
  //   path: '/usertype/list',
  //   component: loadable(() => import('pages/userGroup/list')),
  //   exact: true,
  // },
  // /tutorial
  {
    path: '/transaction',
    component: loadable(() => import('pages/tutorial/list')),
    exact: true,
  },
  {
    path: '/transaction/add-edit',
    component: loadable(() => import('pages/tutorial/add-edit')),
    exact: true,
  },
  {
    path: '/transaction/add-edit/:id',
    component: loadable(() => import('pages/tutorial/add-edit')),
    exact: true,
  },

  {
    path: '/tutorial/list/preview',
    component: loadable(() => import('pages/tutorial/add-edit/preview')),
    exact: true,
  },
  // /transaction
  // {
  //   path: '/transaction',
  //   component: loadable(() => import('pages/transaction/list')),
  //   exact: true,
  // },
  // {
  //   path: '/users-management/list/:userId',
  //   component: loadable(() => import('pages/users-management-new/details')),
  //   exact: true,
  // },
  // Users management
  // {
  //   path: '/users-management',
  //   component: loadable(() => import('pages/users-management')),
  //   exact: true,
  // },
  // {
  //   path: '/users-management/view-user/:id',
  //   component: loadable(() => import('pages/users-management/view-user')),
  //   exact: true,
  // },
  // {
  //   path: '/users-management/edit-user/:id',
  //   component: loadable(() => import('pages/users-management/edit-user')),
  //   exact: true,
  // },
  // /widgets
  // {
  //   path: '/widgets',
  //   component: loadable(() => import('pages/widgets')),
  //   exact: true,
  // },
  // {
  //   path: '/widgets/add-edit',
  //   component: loadable(() => import('pages/widgets/add-edit')),
  //   exact: true,
  // },
  // {
  //   path: '/widgets/add-edit/:id',
  //   component: loadable(() => import('pages/widgets/add-edit')),
  //   exact: true,
  // },

  // template
  {
    path: '/template',
    component: loadable(() => import('pages/template/list')),
    exact: true,
  },
  {
    path: '/template/add-edit',
    component: loadable(() => import('pages/template/add-edit')),
    exact: true,
  },
  {
    path: '/template/add-edit/:id',
    component: loadable(() => import('pages/template/add-edit')),
    exact: true,
  },
  // /template/action
  {
    path: '/template/action',
    component: loadable(() => import('pages/templateAction/list')),
    exact: true,
  },
  {
    path: '/template/action/add-edit',
    component: loadable(() => import('pages/templateAction/add-edit')),
    exact: true,
  },
  {
    path: '/template/action/add-edit/:id',
    component: loadable(() => import('pages/templateAction/add-edit')),
    exact: true,
  },

  // AntDesign
  {
    path: '/antd',
    component: loadable(() => import('pages/antd')),
    exact: true,
  },
]

@connect(({ user }) => ({ user }))
class Router extends React.Component {
  render() {
    const { history, user } = this.props
    console.log('9999', user)
    return (
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <ConnectedRouter history={history}>
            <IndexLayout>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    // return <Redirect to="/dash" />
                    console.log('fffffffffffff', user.role)
                    switch (user.role) {
                      case ROLES.admin:
                        console.log('444')
                        return <Redirect to="/category" />
                      // case ROLES.hospital:
                      //   console.log('888')
                      //   return <Redirect to="/modules" />
                      default:
                        return <Redirect to="/user/login" />
                    }
                  }}
                />
                <Suspense fallback={<Loader />}>
                  {routes.map((route) => {

                    if (route.authorize) return <PrivateRoute key={route.path} {...route} />
                    return (
                      <Route
                        path={route.path}
                        component={route.component}
                        key={route.path}
                        exact={route.exact}
                      />
                    )
                  })}
                </Suspense>
                <Route component={NotFoundPage} />
              </Switch>
            </IndexLayout>
          </ConnectedRouter>
        </Suspense>
      </ErrorBoundary>
    )
  }
}

export default Router
